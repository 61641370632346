import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { BusinessAccountForm } from "../components/business-account-app-form"

const BusinessAccountAppPage = (props) => {
    const businessData = props.data.allContentfulBusinessAccountApplication.edges
    return (
        <Layout>
            <Seo
                title="Business Account Application - L&D Solutions Inc."
                location={props.location.pathname}
            />
            <div className="bg-newlndgray pt-16 lg:pt-0">
                <div className="container mx-auto px-5 pb-14 -mt-16 pt-10 lg:pt-32 lg:-mb-24">
                    <div className="lg:mb-32">
                        <div className="text-center text-sm text-white font-bold max-w-3xl mx-auto">
                            <h1 className="text-black text-3xl">{businessData[0].node.title.title}</h1>
                            <div className="py-5 text-black font-light text-base text-left" dangerouslySetInnerHTML={{
                                __html: businessData[0].node.bodyText.childMarkdownRemark.html,
                            }} />
                        </div>
                        <div className="text-center text-sm font-bold max-w-3xl mx-auto px-2">
                            <h1 className="text-2xl">Instructions</h1>
                            <ul className="font-light text-left list-disc space-y-2 py-3" dangerouslySetInnerHTML={{
                                __html: businessData[0].node.instructions.childMarkdownRemark.html,
                            }} />
                        </div>
                        <div className="mt-5">
                            <BusinessAccountForm formName="Business Account Application" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default BusinessAccountAppPage
export const pageQuery = graphql`
query{
    allContentfulBusinessAccountApplication {
        edges {
          node {
            title {
              title
            }
            bodyText {
              childMarkdownRemark {
                html
              }
            }
            instructions {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
}
`
