import React, { Component } from "react"
import Lock from "../icons/lock"

export class BusinessAccountForm extends Component {
    render() {
        return (
            <form name={this.props.formName}
                enctype="multipart/form-data"
                method="POST"
                data-netlify="true"
                action="/success"
                className="w-full max-w-3xl px-14 py-5 lg:px-6 xl:px-14 bg-white border-2 rounded-2xl mx-auto shadow-xl"
            >
                <input type="hidden" name="form-name" value={this.props.formName} />
                <p className="text-center text-3xl font-bold mt-2">Fast Replies Guaranteed!</p>
                <hr className="my-5 border border-gray-400" />
                <div className="flex flex-wrap -mx-2 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">First Name
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred" id="grid-first-name" type="text" name="first name" placeholder="Ex: Jane" />
                        </label>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">Last Name
                            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-lndred" id="grid-last-name" type="text" name="last name" placeholder="Ex: Doe" />
                        </label>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-2 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="company-name">Company Name
                            <span className="text-lndred">*</span>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred" id="company-name" type="text" name="company" required placeholder="Ex: L&D Solutions" />
                        </label>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Phone
                            <span className="text-lndred">*</span>
                            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-lndred" id="phone" name="phone" type="tel" required placeholder="Ex: 123-456-7890" />
                        </label>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" required htmlFor="company-address">Company Address
                            <span className="text-lndred">*</span>
                            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred" id="company-address" type="company-address" name="company-address" required placeholder="Ex: 7255 32nd St, North Highlands, CA 95660" />
                        </label>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" required htmlFor="email">E-mail
                            <span className="text-lndred">*</span>
                            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred" id="email" type="email" name="email" required placeholder="Ex: JaneDoe@mail.com" />
                        </label>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                            <span className="text-lndred">*</span>
                            <span>Proof of Business License: </span>
                            <input name="file" type="file" required />
                        </label>
                    </div>
                </div>
                <div className="flex text-sm items-center text-gray-300 justify-center text-center">
                    <Lock />
                    <span className="pl-2">
                        We Value Your Privacy
                    </span>
                </div>
                <div className="flex justify-center py-5 mx-auto">
                    <button type="submit" title="Send Message" alt="Send Message" className="shadow bg-lndred border hover:shadow-lg hover:shadow-lndred hover:border-lndred focus:shadow-outline focus:outline-none text-white font-semibold py-2 px-4 rounded-xl w-48 transition duration-150 ease-in-out" >Send Message</button>
                </div>
            </form>
        )
    }
}